<script>
import Layout from "./../layouts/main";
import appConfig from "../../app.config";
import {localeComputed} from "@/state/helpers";
import { useRouter } from 'vue-router'

const router = useRouter();

export default {
  page: {
    meta: [{ name: "description", content: appConfig.description }],
  },
  name: "FAQs",
  methods: {
    router() {
      return router;
    },
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  components: {
    Layout,
    ...localeComputed
  },
};
</script>

<template>
  <Layout>
      <div class="row">
            <div class="col-lg-12">
                <div class="card rounded-0 bg-soft-success mx-n4 mt-n4 border-top">
                    <div class="px-4">
                        <div class="row">
                            <div class="col-xxl-5 align-self-center">
                                <div class="py-4">
                                    <h4 class="display-6 coming-soon-text">{{ $t("t-doc-title") }}</h4>
                                    <p class="text-success fs-15 mt-3">{{ $t("t-doc-description") }}</p>
                                </div>
                            </div>
                            <div class="col-xxl-3 ms-auto">
                                <div class="mb-n5 mt-5 pb-1 faq-img d-none d-xxl-block">
                                    <img src="@/assets/images/user-illustarator-2.png" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end card body -->
                </div>
                <!-- end card -->

                <div class="row justify-content-evenly">
                    <div class="col-lg-4">
                      <div class="mt-3">
                        <div class="d-flex align-items-center mb-2">
                          <div class="flex-shrink-0 me-1">
                            <i class="ri-file-list-3-line fs-24 align-middle text-success me-1"></i>
                          </div>
                          <div class="flex-grow-1">
                            <h5 class="fs-16 mb-0 fw-semibold">{{ $t("t-doc-legal-documents") }}</h5>
                          </div>
                        </div>

                        <ul class="list-group">
                          <li class="list-group-item">
                            <a :href='$t("t-doc-terms-and-conditions_path")'>
                              <span>{{ $t("t-doc-terms-and-conditions") }}</span>
                            </a>
                          </li>
                          <li class="list-group-item">
                            <a :href='$t("t-doc-privacy-policy_path")'>
                              <span>{{ $t("t-doc-privacy-policy") }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                  <div class="col-lg-4">
                    <div class="mt-3">
                      <div class="d-flex align-items-center mb-2">
                        <div class="flex-shrink-0 me-1">
                          <i class="ri-todo-line fs-24 align-middle text-success me-1"></i>
                        </div>
                        <div class="flex-grow-1">
                          <h5 class="fs-16 mb-0 fw-semibold">{{ $t("t-doc-manuals") }}</h5>
                        </div>
                      </div>

                      <ul class="list-group">
                        <li class="list-group-item">
                          <a :href='$t("t-doc-manuals-doc1_path")'>
                            <span>{{ $t("t-doc-manuals-doc1") }}</span>
                          </a>
                        </li>
                        <li class="list-group-item">
                          <a href='/documents/bitget/integration-with-us'>
                            <span>{{ $t("t-doc-manuals-doc2") }}</span>
                          </a>
                        </li>
                        <li class="list-group-item">
                          <a href='/files/binance-futures-account-and-damask.pdf'>
                            <span>{{ $t("t-doc-manuals-doc3") }}</span>
                          </a>
                        </li>
                        <li class="list-group-item" v-if="currentLocale() === 'ru'">
                          <a href='https://www.binance.com/ru/support/faq/%D0%B2%D1%8B%D0%B2%D0%BE%D0%B4-%D1%81%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%B2-%D1%81-binance-115003670492'>
                            <span>{{ $t("t-doc-manuals-doc4") }}</span>
                          </a>

                        </li>
                        <li class="list-group-item" v-if="currentLocale() === 'en' || currentLocale() === 'pl'">
                          <a href=' https://www.binance.com/en/support/faq/how-to-withdraw-from-binance-115003670492'>
                            <span>{{ $t("t-doc-manuals-doc4") }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="mt-3">
                      <div class="d-flex align-items-center mb-2">
                        <div class="flex-shrink-0 me-1">
                          <i class="ri-todo-line fs-24 align-middle text-success me-1"></i>
                        </div>
                        <div class="flex-grow-1">
                          <h5 class="fs-16 mb-0 fw-semibold">{{ $t("t-doc-strategy-and-tools") }}</h5>
                        </div>
                      </div>

                      <ul class="list-group">
                        <li class="list-group-item">
                          <a :href='$t("t-doc-strategy-strategy-path")'>
                            <span>{{ $t("t-doc-trading-strategy") }}</span>
                          </a>
                        </li>
                        <li class="list-group-item">
                          <a :href='$t("t-doc-strategy-support-and-resistance-path")'>
                            <span>{{ $t("t-doc-support-and-resistance-levels") }}</span>
                          </a>
                        </li>
                        <li class="list-group-item">
                          <a :href='$t("t-doc-strategy-smart-average-path")'>
                            <span>{{ $t("t-doc-smart-averaging") }}</span>
                          </a>
                        </li>
                        <li class="list-group-item">
                          <a :href='$t("t-doc-strategy-dynamic-take-profit-path")'>
                            <span>{{ $t("t-doc-dynamic-take-profit") }}</span>
                          </a>
                        </li>
                        <li class="list-group-item">
                          <router-link to="create-bot">
                            <span>{{ $t("t-doc-risk-money-management") }}</span>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
            </div><!--end col-->.
        </div><!--end row-->
    </Layout>
</template>
